import request from '@/tools/request';
import { Res } from '@/types/common';
import { Ref } from 'vue';
import {
  ChartsData,
  CommonFilter,
  Config,
  LogsData,
  OverageData,
  Proportion,
  ConsumeData,
  PayUrlData,
} from '../types/statistics';

export const getConfig = loading => {
  return request({
    url: '/loqateauto-index-config.html',
    loading,
  }) as Promise<Res<Config>>;
};

/**
 * 更新配置
 */
export const updateConfig = (data: Config, loading: Ref<boolean>) => {
  return request({
    url: '/loqateauto-index-saveConfig.html',
    loading,
    method: 'POST',
    data,
  });
};

/**
 * 获取余额
 */
export const getOverage = (loading?: Ref<boolean>) => {
  return request({
    url: '/loqateauto-balance-balance.html',
    loading,
  }) as Promise<Res<OverageData>>;
};

/**
 * 获取比例
 */
export const getProportion = (params: CommonFilter, loading: Ref<boolean>) => {
  return request({
    url: '/loqateauto-record-scaleList.html',
    params,
    loading,
  }) as Promise<Res<Proportion[]>>;
};

/**
 * 获取折线图数据
 */
export const getChartsData = (params: CommonFilter, loading: Ref<boolean>) => {
  return request({
    url: '/loqateauto-record-lineChart.html',
    loading,
    params,
  }) as Promise<Res<ChartsData>>;
};

/**
 * 获取补全记录
 */
export const getLogs = (
  params: CommonFilter & { page_size: number; page: number },
  loading: Ref<boolean>,
) => {
  return request({
    url: '/loqateauto-record-list.html',
    loading,
    params,
  }) as Promise<Res<LogsData>>;
};

/**
 * 消耗明细
 */
export const getConsume = (
  params: { trade_no: string; page: number; page_size: number },
  loading: Ref<boolean>,
) => {
  return request({
    url: '/loqateauto-balance-list.html',
    loading,
    params,
  }) as Promise<Res<ConsumeData>>;
};

/**
 * 获取支付url
 */
export const getPayUrl = (params: { proudctsid: any }, loading: Ref<boolean>) => {
  return request({
    url: '/loqateauto-index-appCheckout.html',
    loading,
    params,
  }) as Promise<Res<PayUrlData>>;
};
