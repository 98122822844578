
import { defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { ElButton, ElInput, ElTableColumn } from 'element-plus';
import { Consume } from '../../types/statistics';
import { getConsume, getOverage } from '../../api/statistics';
import { getUrlParams, parseTime } from '@/tools';
import PageHeader from '@/components/pageHeader/PageHeader.vue';

if (getUrlParams('open')) window.parent.location.href = `${location.origin}${location.pathname}`;

export default defineComponent({
  name: 'LexionAdmin',
  setup() {
    const tableList = ref<Consume[]>([]);
    const loading = ref(false);

    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPages: 1,
    });
    const keyword = ref('');
    // 初始化
    const initTable = async () => {
      const res = await getConsume(
        {
          page: pagination.value.currentPages || 1,
          page_size: pagination.value.pageSize,
          trade_no: keyword.value,
        },
        loading,
      );

      if (!res.status) return;
      const { data } = res;
      if (!data) return;
      tableList.value = data.list || [];
      pagination.value.total = Number(data.paginate.count) || 0;
    };

    // 换页
    const pageChange = cur => {
      pagination.value.currentPages = cur;
      initTable();
    };

    // 搜索
    const search = () => {
      pagination.value.currentPages = 1;
      initTable();
    };

    // 初始化余额
    const overage = ref(0);
    const initOverage = async () => {
      const { data } = await getOverage();
      overage.value = data?.sub_total || 0;
    };

    onMounted(() => {
      initTable();
      initOverage();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      pageChange,
      keyword,
      search,
      parseTime,
      overage,
    };
  },
  components: { MTable, ElInput, ElTableColumn, ElButton, PageHeader },
});
